
.antd-user-card {
    cursor: pointer;
    padding: 4px 0;
}

.antd-user-card:hover, .antd-user-card:active{
    background: var(--ant-color-bg-elevated);
    transition: all var(--ant-motion-duration-mid) var(--ant-motion-ease-in-out);
}

.antd-user-card .ant-card-meta-avatar {
    align-self: center;
    margin: 0 8px !important;
    margin-top: 4px !important;
    padding: 0 !important;
}

.antd-user-card .ant-card-body {
    margin: 4px 8px !important;
    padding: 0 !important;
}

.antd-user-card .ant-card-meta-title {
    padding: 0 !important;
    margin: 0 4px !important;
    font-size: 1.1em !important;
}

.antd-user-card .ant-card-meta-description {
    margin: 0 4px !important;
    padding: 0 !important;
    font-size: 0.9em !important;
}
