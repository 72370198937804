@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: "Sora", sans-serif;
    outline: none;
    text-decoration: none;
    margin: 0;
}

a {
    color: inherit;
}

.ant-layout-sider-trigger {
    background: #0E0E0E !important;
}

path.react-flow__edge-path {
    stroke-width: 2;
}

.cursor-pointer {
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background-color: var(--ant-color-bg);
}

::-webkit-scrollbar-thumb {
    background-color: var(--ant-color-primary-bg);
    border-radius: var(--ant-border-radius);
}

.react-flow__pane{
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
